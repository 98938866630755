import React from "react";
import NewsletterForm from "../newsletter/NewsletterForm";
const Newsletter = ({ show, hide, newsletter }) => {
  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center w-full h-screen transition duration-500 transform pointer-events-none ${
        show && !newsletter
          ? "-translate-y-12 md:translate-y-0 opacity-100"
          : "md:translate-y-12 opacity-0"
      }`}
    >
      <div
        className={`w-full max-w-xs p-4 text-xl leading-none text-orange bg-red ${
          show && !newsletter ? "pointer-events-auto" : "pointer-events-none"
        }`}
      >
        <button onClick={hide}>X</button>

        <NewsletterForm />
      </div>
    </div>
  );
};

export default Newsletter;
