import React from "react";
import Logo from "../logo/Logo";
import "./loader.css";
import { HeaderContext } from "../../context/header-context";
const Loader = () => {
  const { loadOnce } = React.useContext(HeaderContext);
  const background = React.useRef(null);
  const logo = React.useRef(null);

  React.useEffect(() => {
    if (background.current) {
      background.current.classList.add("bg-opacity-0");
    }
    let timer = setTimeout(() => {
      if (logo.current) {
        logo.current.classList.add("opacity-0");
      }
    }, 1250);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    !loadOnce && (
      <div
        className={`z-50 fixed inset-0  flex flex-col items-center justify-center w-full h-full px-12 bg-orange loading-page`}
        ref={background}
      >
        <div className="w-full transition duration-300" ref={logo}>
          <Logo animate={true}></Logo>
        </div>
      </div>
    )
  );
};

export default Loader;
