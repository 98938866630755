import React, { useEffect } from "react"
// import Cookies from "universal-cookie";
import { useCookies } from "react-cookie"
import "./customform.scss"
const CustomForm = ({ status, message, onValidated }) => {
  const [cookies, setCookie] = useCookies(["newsletter"])
  let email
  const submit = () => {
    email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      })
  }
  useEffect(() => {
    // const cookies = new Cookies();
    if (status === "success" && !cookies.newsletter) {
      setCookie("newsletter", "true", {
        path: "/",
        maxAge: 1000 * 60 * 60 * 24 * 7,
        secure: true,
        sameSite: "none",
      })
    }
  }, [status, setCookie, cookies])

  return (
    <div>
      <form className="flex flex-col w-full" onSubmit={e => e.preventDefault()}>
        <header className="my-12">
          {status === "sending" && <div>Sending...</div>}
          {status === "error" && (
            <div dangerouslySetInnerHTML={{ __html: message }} />
          )}
          {status === "success" && <div>Thanks for subscribing!</div>}
          {status === null && (
            <span>
              SUBSCRIBE TO OUR <br />
              MAILING LIST FOR <br />
              THE LATEST ARRIVALS, <br />
              EVENTS & PROMOS.
            </span>
          )}
        </header>
        <input
          type="email"
          className="w-full pt-2 mb-2 placeholder-current uppercase bg-transparent border-b border-current md:border-b-2 placeholder-orange"
          placeholder="Enter email"
          ref={node => (email = node)}
        />
        <footer className="flex items-end mt-3">
          {status !== "sending" && status !== "success" && (
            <button
              type="submit"
              className="w-auto ml-auto uppercase"
              onClick={submit}
            >
              Submit
            </button>
          )}
        </footer>
      </form>
    </div>
  )
}

export default CustomForm
