import React, { useState, useEffect } from "react"
import Header from "./Header"
import Loader from "./Loader"
// import Cookies from "universal-cookie";
import { useCookies } from "react-cookie"
import Newsletter from "../modals/Newsletter"
import CookieModal from "../modals/CookieModal"
import { HeaderContext } from "../../context/header-context"
const Layout = ({ children }) => {
  const [showNewsletter, setNewsletter] = useState(false)
  const [showCookie, toggleCookie] = useState(false)
  // const cookies = new Cookies();
  const [cookies, setCookie] = useCookies(["newsletter", "cookie"])
  const { loadOnce } = React.useContext(HeaderContext)
  const newsletter = cookies.newsletter
  // const getNewsletter = cookies.get("newsletter");
  // const newsletter = getNewsletter
  //   ? JSON.parse(cookies.get("newsletter"))
  //   : null;
  const cookie = cookies.cookie

  useEffect(() => {
    if (!loadOnce) {
      var timer1 = setTimeout(function () {
        toggleCookie(true)
      }, 4000)

      var timer2 = setTimeout(function () {
        setNewsletter(true)
      }, 9000)
    } else {
      var timer3 = setTimeout(function () {
        toggleCookie(true)
      }, 1000)

      var timer4 = setTimeout(function () {
        setNewsletter(true)
      }, 5000)
    }
    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)
      clearTimeout(timer3)
      clearTimeout(timer4)
    }
  }, [loadOnce])
  const hide = () => {
    setNewsletter(false)
    setTimeout(function () {
      setCookie("newsletter", "true", {
        path: "/",
        maxAge: 1000 * 60 * 60 * 24 * 7,
        secure: true,
        sameSite: "none",
      })
    }, 500)
  }
  const hideCookies = () => {
    toggleCookie(false)
    setTimeout(function () {
      setCookie("cookie", "true", {
        path: "/",
        maxAge: 1000 * 60 * 60 * 24 * 7,
        secure: true,
        sameSite: "none",
      })
    }, 500)
  }
  return (
    <div>
      <Loader />
      <Header />
      <div className="pt-12 md:pt-32">{children}</div>
      <Newsletter
        show={showNewsletter}
        newsletter={newsletter}
        hide={() => hide()}
      />
      <CookieModal
        show={showCookie}
        cookie={cookie}
        hide={() => hideCookies()}
      />
    </div>
  )
}

export default Layout
