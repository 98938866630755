import React from "react";

const ExternalLink = ({ props }) => {
  return (
    <a
      class="transition-colors duration-300 btn"
      href={props.href}
      target="_blank"
      rel="noreferrer"
    >
      <slot />
    </a>
  );
};

export default ExternalLink;
