import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import ExternalLink from "./ExternalLink";
import InternalLink from "./InternalLink";
import Em from "./Em";
const RichText = ({ blocks, className }) => {
  return (
    <BlockContent
      blocks={blocks}
      className={className}
      serializers={{
        marks: { ExternalLink, InternalLink, Em },
      }}
    />
  );
};

export default RichText;
