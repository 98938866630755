import React from "react";
import { Link } from "gatsby";
import Logo from "../logo/Logo";
import { StoreContext } from "../../context/store-context";
const Header = () => {
  const { checkout } = React.useContext(StoreContext);

  const items = checkout ? checkout.lineItems : [];

  const quantity = items.reduce((total, item) => {
    return total + item.quantity;
  }, 0);
  const [animate, setAnimate] = React.useState(false);
  return (
    <header className="fixed top-0 left-0 z-50 flex items-start justify-between w-full p-3 leading-none  lg:tracking-tighter md:p-4">
      <figure
        onMouseEnter={() => setAnimate(true)}
        onMouseLeave={() => setAnimate(false)}
        role="presentation"
        className="2xs:h-9 h-11 -mt-2.5 xs:-mt-2.5 xs:h-10 md:h-16 lg:h-16 lg:-mt-4"
      >
        <Link to="/" className="relative block h-full logo group">
          <Logo animate={animate} svgclassName="h-full w-auto" />
        </Link>
      </figure>
      <div
        className="
            text-base
            xs:leading-none
            xs:text-sm
            md:text-4xl
            lg:text-5xl
            -mt-0.5
            md:mt-0.5
            lg:-mt-0.5
            leading-none
          "
      >
        <h1>OLD KINDA FUN BOOKS</h1>
        <nav className="flex justify-between w-full">
          <Link to="/cart">cart [ {quantity} ]</Link>
          <Link to="/about">About</Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
