import React from "react";
import { Link } from "gatsby";
const InternalLink = ({ link, title }) => {
  return (
    <Link className="btn" to={link}>
      <span
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      ></span>
    </Link>
  );
};

export default InternalLink;
