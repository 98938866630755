import React from "react";

const CookieModal = ({ show, hide, cookie }) => {
  return (
    <div
      className={`${
        show && !cookie ? "-translate-x-1/4 -rotate-30" : "translate-x-full"
      } fixed bottom-0 right-0 z-50 w-32 p-3 pb-32 -mb-32 text-center transition duration-500 transform md:w-40 md:p-4 md:pb-32 bg-red text-orange`}
    >
      <svg
        className="w-full h-auto"
        viewBox="0 0 124 77"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M115.22 68.13C120.61 62.34 123.31 55.25 123.31 46.87C123.31 38.49 120.61 31.4 115.22 25.61C109.83 19.82 102.65 16.92 93.68 16.92C84.71 16.92 77.53 19.82 72.14 25.61C66.75 31.4 64.05 38.49 64.05 46.87C64.05 55.25 66.74 62.34 72.14 68.13C77.53 73.92 84.71 76.82 93.68 76.82C102.65 76.82 109.83 73.92 115.22 68.13ZM115.7 46.87C115.7 53.28 113.72 58.8 109.77 63.44C105.82 68.08 100.45 70.41 93.68 70.41C86.9 70.41 81.54 68.09 77.59 63.44C73.64 58.8 71.66 53.27 71.66 46.87C71.66 40.46 73.63 34.94 77.59 30.3C81.54 25.66 86.9 23.33 93.68 23.33C100.46 23.33 105.82 25.65 109.77 30.3C113.73 34.94 115.7 40.47 115.7 46.87ZM51.16 68.13C56.55 62.34 59.25 55.25 59.25 46.87C59.25 38.49 56.55 31.4 51.16 25.61C45.77 19.82 38.59 16.92 29.62 16.92C20.65 16.92 13.47 19.82 8.08 25.61C2.69 31.41 0 38.49 0 46.87C0 55.25 2.69 62.34 8.09 68.13C13.48 73.92 20.66 76.82 29.63 76.82C38.59 76.82 45.77 73.92 51.16 68.13ZM51.65 46.87C51.65 53.28 49.67 58.8 45.72 63.44C41.77 68.08 36.4 70.41 29.63 70.41C22.85 70.41 17.49 68.09 13.54 63.44C9.59 58.8 7.61 53.27 7.61 46.87C7.61 40.46 9.58 34.94 13.54 30.3C17.49 25.66 22.85 23.33 29.63 23.33C36.41 23.33 41.77 25.65 45.72 30.3C49.67 34.94 51.65 40.47 51.65 46.87Z"
          className="fill-current"
        />
        <path
          d="M32.14 29.42C27.08 28.47 21.92 32.25 21.89 37.61C21.86 42.61 26.73 46.19 31.48 45.81C36.85 45.38 41.14 39.22 37.66 34.4C36.05 32.17 33.06 31.1 30.51 32.34C28.02 33.55 26.56 36.44 27.5 39.14C28.24 37.83 28.98 36.53 29.73 35.22C27.14 35.66 26.63 38.44 27.52 40.47C27.93 41.41 29.27 42.12 30.27 42.05C32.61 41.88 34.5 39.93 33.7 37.48C32.44 33.59 26.28 35.26 27.55 39.17C27.58 39.26 27.61 39.35 27.64 39.43C27.75 38.61 27.85 37.79 27.96 36.97C27.9 37.05 27.85 37.14 27.79 37.22C28.71 36.69 29.62 36.17 30.54 35.64C30.45 35.65 30.36 35.65 30.26 35.66C31.18 36.19 32.09 36.71 33.01 37.24C32.97 37.16 32.94 37.07 32.9 36.99C32.9 38.06 32.9 39.14 32.9 40.21C32.95 40.12 33 40.03 33.04 39.94C32.4 40.43 31.77 40.92 31.14 41.4C31.23 41.38 31.32 41.37 31.41 41.35C33.03 41.07 34.16 38.93 33.64 37.43C33.79 37.87 32.57 38.23 32.57 38.23C32.31 38.47 32.26 38.88 31.93 39.13C31.17 39.69 30.07 39.52 29.3 39.07C27.62 38.1 28.46 35.18 30.45 35.55C34.45 36.32 36.17 30.17 32.14 29.42Z"
          className="fill-current"
        />
        <path
          d="M96 30.41C91.12 28.98 85.27 32.31 85.18 37.71C85.09 42.95 90.72 47.41 95.66 44.92C100.02 42.72 101.33 36.02 97.01 33.07C93.37 30.59 87.35 32.48 86.55 37.06C86.31 38.42 86.69 39.96 88.01 40.66C88.72 41.03 89.43 41.41 90.14 41.78C93.77 43.69 96.99 38.19 93.36 36.28C92.65 35.91 91.94 35.53 91.23 35.16C91.72 36.36 92.21 37.56 92.69 38.76C92.84 37.9 92.78 38.54 92.62 38.57C92.23 38.63 93.08 38.41 92.74 38.44C93.1 38.41 93.42 38.21 93.48 38.38C93.31 37.93 93.13 39.12 92.81 39.25C92.23 39.48 91.38 38.38 91.56 37.67C91.84 36.52 93.35 36.3 94.29 36.58C98.25 37.71 99.93 31.56 96 30.41Z"
          className="fill-current"
        />
        <path
          d="M46.4 7.06C34.48 -0.159999 19.86 -0.619999 7.42 5.56C3.75 7.39 6.97 12.89 10.64 11.06C21.04 5.89 33.28 6.56 43.18 12.56C46.7 14.7 49.91 9.19 46.4 7.06Z"
          className="fill-current"
        />
        <path
          d="M71.83 11.66C83.41 6.10002 96.5 5.68002 108.52 10.08C112.38 11.49 114.04 5.33002 110.21 3.93002C96.53 -1.06998 81.69 -0.129979 68.6 6.15002C64.92 7.93002 68.15 13.43 71.83 11.66Z"
          className="fill-current"
        />
      </svg>

      <header className="my-8 text-xs">Accept cookies?</header>
      <nav className="flex flex-col mb-8 text-4xl uppercase md:text-5xl">
        <button className="uppercase" onClick={hide}>
          Yes
        </button>
        <span>/</span>
        <button className="uppercase" onClick={hide}>
          No
        </button>
      </nav>
    </div>
  );
};

export default CookieModal;
